import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LBgSect,
  CPageNav,
  CFaqList,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FAQ',
            sub: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CPageNav
            data={[
              {
                label: 'ご宿泊',
                link: {
                  href: '/faq/',
                },
              },
              {
                label: 'レストラン＆バー',
                link: {
                  href: '/faq/restaurants/',
                },
              },
              {
                label: 'ウエディング',
                link: {
                  href: '/faq/wedding/',
                },
              },
              {
                label: 'ご宴会・会議',
                link: {
                  href: '/faq/banquet/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/faq/facility/',
                },
              },
              {
                label: 'アクセス',
                link: {
                  href: '/faq/access/',
                },
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">ブライダルフェアについて</h2>
          <div className="u_mbExLarge">
            <p className="c_headingLv4">
              ビル大規模修繕工事実施にともなう
              <br className='u_sp' />
              当ホテル営業一時休止前の
              <br />
              ご披露宴の新規受付は終了いたしました。
            </p>
          </div>
          {/* <CFaqList
            hash=""
            data={[
              {
                q: '予約が必要ですか?',
                a: (
                  <>
                    事前予約がおすすめです。招待人数に応じた披露宴会場をご覧いただける時間帯をご案内いたします。
                    <br />
                    なお、試食付ブライダルフェアにつきましては、事前のご予約が必要です。ブライダルサロンへお問合せください。
                    <br />
                    TEL
                    045-221-1122（平日：11:00～17:00／土・日・祝日：9:00～19:00）
                    <br />
                    定休日：祝日を除く火曜日、年末年始
                  </>
                ),
              },
              {
                q: 'いつ頃開催していますか?',
                a: (
                  <>
                    さまざまなブライダルフェアがございますので、詳しくは
                    <Link to="/wedding/fair/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '当日のスケジュールと所要時間はどれくらいですか?',
                a: (
                  <>
                    □ご来館・アンケートのご記入
                    <br />
                    □アンケートに沿ったインタビュー
                    <br />
                    □館内・披露宴会場のご案内
                    <br />
                    □試食会（試食付ブライダルフェアの場合のみ）
                    <br />
                    □日程・見積りのご相談
                    <br />
                    所要時間：約3時間30分
                    <br />
                    <ul className="c_noteList">
                      <li>
                        ご希望に合わせて調整いたしますので、お気軽にご相談ください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: '参加費用はかかりますか?',
                a: <>無料です。</>,
              },
              {
                q: '1人でも参加できますか?',
                a: (
                  <>
                    ご参加いただけます。親御様とご一緒に参加される方もいらっしゃいます。
                  </>
                ),
              },
              {
                q: '車で行きたいのですが、駐車場はありますか？また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル専用スペースではございませんが、駐車場がございます。
                    <br />
                    詳しくは
                    <Link to="/about/access/car/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          /> */}
          <h2 className="c_headingLv2 u_mtExLarge">結婚式について</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '結婚式当日までのスケジュールを教えてください。',
                a: (
                  <>
                    お申込手続きの際に、おふたりにあったスケジュールをご提案いたします。
                    <br />
                    招待人数や内容にもよりますが、最短2週間で準備が可能です。
                  </>
                ),
              },
              {
                q: '住まいが遠方の為、度々打合せに行くことができません。対応できますか?',
                a: (
                  <>
                    おふたりに合わせてスケジュールを組ませていただきますので、お越しいただく回数が少なくても安心して当日をお迎えいただけます。
                    <br />
                    またオンラインでのお打合せも可能です。
                  </>
                ),
              },
              {
                q: '親族のみの会食もできますか?',
                a: (
                  <>
                    20名様より承ります。招待人数に合わせた披露宴会場をご提案いたしますので、お気軽にお問合せください。
                  </>
                ),
              },
              {
                q: '衣裳・引出物の持込みは可能ですか?',
                a: (
                  <>
                    ホテル提携店以外からの新郎新婦様の衣裳・引出物のお持込みに関しては、所定のお持込料を頂戴しております。
                  </>
                ),
              },
              {
                q: '支払いはどのようになりますか?',
                a: (
                  <>
                    お申込手続きの際にお申込金として20万円をお預かりいたします。
                    <br />
                    総額のお支払いは、最終打合せ終了後にお客様が了承された見積金額を、ご披露宴当日の5営業日前までに現金、クレジットカードまたはお振込みにてお支払いください。
                  </>
                ),
              },
              {
                q: '列席者の中で食べ物にアレルギーや食事制限のある人がいます。対応できますか?',
                a: (
                  <>
                    食物アレルギー、持病、宗教、妊娠、授乳中などの食事制限によりお召しあがりいただけない食材がある場合は、事前にお申付けください。
                    <br />
                    <ul className="c_noteList">
                      <li>
                        すべてのアレルギー等に対応できるわけではございませんので、予めご了承ください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                q: '子供連れの列席者にも対応はできますか?',
                a: (
                  <>
                    披露宴会場ではお子様用の椅子やベビーベッドのご用意が可能です。お子様用のお食事は、年齢に応じた内容のお料理をご用意しております。
                    <br />
                    ベビーシッターの手配も承っております。詳しくは
                    <Link to="/facilities/baby/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '列席者の宿泊優待はありますか?',
                a: (
                  <>
                    ご優待料金をご用意しております。料金はお日にちによって変動いたしますので、詳しくは担当コンシェルジュへお問合せください。
                  </>
                ),
              },
              {
                q: '列席者の衣裳レンタルやヘア&メイク・着付けは可能ですか?',
                a: (
                  <>
                    レンタル衣裳はホテル衣裳室「JUNO」、または提携店「Authentique横浜」にて、ご用意しております。また、ヘア&メイク・着付けはホテル美容室「資生堂ビューティーサロン」にて承ります。いずれも予約制ですので、挙式日の3か月前から1か月前までにご予約ください。
                    <br />
                    「JUNO」：<a href="tel:0452250320">TEL 045-225-0320</a>
                    <br />
                    「Authentique横浜」：
                    <a href="tel:0452276301">TEL 045-227-6301</a>
                    <br />
                    「資生堂ビューティーサロン」：
                    <a href="tel:0452122030">TEL 045-212-2030</a>
                    <br />
                    定休日：祝日を除く火曜日、年末年始
                  </>
                ),
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">ご列席の皆様へ</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '衣裳のレンタルやヘア&メイク・着付けは可能ですか?',
                a: (
                  <>
                    レンタル衣裳はホテル衣裳室「JUNO」、または提携店「Authentique横浜」にて、ご用意しております。また、ヘア&メイク・着付けはホテル美容室「資生堂ビューティーサロン」にて承ります。いずれも予約制ですので、挙式日の3か月前から1か月前までにご予約ください。
                    <br />
                    「JUNO」：<a href="tel:0452250320">TEL 045-225-0320</a>
                    <br />
                    「Authentique横浜」：
                    <a href="tel:0452276301">TEL 045-227-6301</a>
                    <br />
                    「資生堂ビューティーサロン」：
                    <a href="tel:0452122030">TEL 045-212-2030</a>
                    <br />
                    定休日：祝日を除く火曜日、年末年始
                  </>
                ),
              },
              {
                q: '留袖・タキシード、その他ご列席衣裳の事前送付したいのですが可能ですか?',
                a: (
                  <>
                    留袖・タキシード、その他ご列席衣裳の事前送付が可能です。
                    <br />
                    ご自身でお召しになる場合は、「宴会棟1F
                    メインクローク」にお送りいただき、挙式当日にお取出しください。
                    <br />
                    美容室にて着付けをご予約の場合は、「宴会棟B1F
                    美容室」にお送りください。
                    <br />
                    いずれの場合も、挙式日の1週間前よりお預かりいたします。
                    <br />
                    宛先：
                    <br />
                    〒220-8173 横浜市西区みなとみらい2-2-1-3 <br />
                    横浜ロイヤルパークホテル
                    <br />
                    ○○気付
                    <br />
                    <a href="tel:0452211122">TEL 045-221-1122</a>
                    <br />
                    記載事項：
                    <br />
                    挙式日、ご参列のご両家名、お荷物の内容、着用されるお客様名
                  </>
                ),
              },
              {
                q: '更衣室はありますか?',
                a: (
                  <>
                    宴会棟B1Fに更衣室がございます。ご予約制ではありませんが、週末は混雑する可能性がございますので、お時間に余裕をもってご利用ください。
                  </>
                ),
              },
              {
                q: 'ホテルから荷物を発送をすることはできますか?',
                a: <>ホテル棟1F ベルキャプテンデスクにて承ります。</>,
              },
              {
                q: '車椅子でホテル館内を移動することはできますか?',
                a: (
                  <>
                    スロープやエレベーターなどを各所に設置してありますので、車椅子の方にもご利用いただきやすい設計になっております。また、ホテルをご利用のお客様には車椅子の貸出しも承っております。ご希望の際はご予約をお願いいたします。
                    <br />
                    また、多目的トイレは、ホテル棟70F・68F・B1F、宴会棟2F・3F・4Fにございます。
                  </>
                ),
              },
              {
                q: '子どもを連れて行きます。授乳室やおむつ替えコーナーはありますか?',
                a: (
                  <>
                    ご用意しております。詳しくは
                    <Link to="/facilities/nursing_room/">こちら</Link>
                    をご覧ください。
                    <br />
                    披露宴会場ではお子様用の椅子やベビーベッドのご用意が可能です。お子様用のお食事は、年齢に応じた内容のお料理をご用意しております。
                    <br />
                    ベビーシッターの手配も承っております。詳しくは
                    <Link to="/facilities/baby/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '車で行きたいのですが、駐車場はありますか？また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル専用スペースではございませんが、駐車場がございます。
                    <br />
                    詳しくは
                    <Link to="/about/access/car/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '羽田空港と成田空港へのアクセスはどのようになりますか?',
                a: (
                  <>
                    ホテルより直行バスが運行しております。
                    <br />
                    詳しくは
                    <Link to="/about/access/bus/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '喫煙所はありますか?',
                a: <>ホテル棟1F・68F、宴会棟2Fに喫煙所がございます。</>,
              },
              {
                q: '宿泊をしたいのですが。',
                a: (
                  <>
                    ご優待料金をご用意しております。料金はお日にちによって変動いたしますので、詳しくはブライダルサロンへお問合せください。
                    <br />
                    <a href="tel:0452211122">TEL 045-221-1122</a>
                    （平日：11:00～17:00／土・日・祝日：9:00～19:00）
                    <br />
                    定休日：祝日を除く火曜日、年末年始
                  </>
                ),
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mtExLarge">その他</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '祝電を送りたいのですが、どのように手配すればよいですか?',
                a: (
                  <>
                    下記宛先へ記載事項を記入のうえ、挙式日の前日までにお送りください。
                    <br />
                    宛先：
                    <br />
                    〒220-8173 神奈川県横浜市西区みなとみらい2-2-1-3
                    <br />
                    横浜ロイヤルパークホテル ブライダルサロン気付
                    <br />
                    <a href="tel:0452211122">TEL 045-221-1122</a>
                    <br />
                    記載事項：
                    <br />
                    挙式日、ご両家名（もしくはどちらかのお名前）、送り主
                  </>
                ),
              },
              {
                q: '顔合せや結納をしたいのですが。',
                a: (
                  <>
                    各レストランにて承ります。詳しくはレストラン予約へお問合せください。
                    <br />
                    <a href="tel:0452211155">TEL 045-221-1155</a>
                    （10:00～18:30）
                  </>
                ),
              },
              {
                q: 'サービス料はいくらですか?',
                a: <>本体価格の15%を頂戴いたします。</>,
              },
              {
                q: 'wifiは利用できますか?',
                a: (
                  <>
                    ホテルご利用のお客様は無料でご利用いただけます。
                    <br />
                    詳しくは
                    <Link to="/facilities/business/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LContact
        exClass="u_telUnit_end"
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
